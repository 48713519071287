(function (){
    "use strict";

    // home - company profile impression
    TEAN.module.visible_on_scroll({
        'query' : '#explore-companies .col-md-4.col-xs-12',
        'threshold' : '40%',
        'autoDetach': true
    }, function (ele, index, list) {
        var image = ele.querySelector('.box-avatar');
        var image_url = image.style.backgroundImage || image.dataset.bg;
        image_url = image_url.replace(/^url\(["']?/, '').replace(/["']?\)$/, '');
        var c_id = parseInt(image_url.split('/')[4]);
        var c_name = ele.querySelector('.box-details .box-details-title').innerText;
        var total_elements = list.length;
        if (c_id) {
            return ['company', 'impression', {
                'position': {
                    'index': index+1,
                    'total_elements': total_elements
                },
                'company': {
                    'id': c_id,
                    'name': c_name
                },
                'source': {
                    'element': 'home',
                    'page': 'home',
                    'href': window.location.href
                },
                'event': {
                    'type': 'impression'
                }
            }];
        }
    });

    // home - company profile interaction
    TEAN.tracker.event.attach('#explore-companies .col-md-4.col-xs-12 a', 'click', 'clicks', function(e){
        var href = e.currentTarget.attributes.href.textContent;
        var image = e.currentTarget.querySelector('.box-avatar');
        var image_url = image.style.backgroundImage || image.dataset.bg;
        image_url = image_url.replace(/^url\(["']?/, '').replace(/["']?\)$/, '');
        var c_id = parseInt(image_url.split('/')[4]);
        var card = e.currentTarget.parentElement;
        var c_name = card.querySelector('.box-details .box-details-title').innerText;
        var list = card.parentElement;
        var index = TEAN.helper.get_element_index(list.children, card);
        if (c_id) {
            return ['company', 'interaction', {
                'position': {
                    'index': index,
                    'total_elements': list.children.length
                },
                'company': {
                    'id': c_id,
                    'name': c_name
                },
                'source': {
                    'element': 'home',
                    'page': 'home',
                    'href': window.location.href
                },
                'event': {
                    'type': 'click',
                    'href': href
                }
            }];
        }
    }, true);

    // home - Latest jobs impression
    TEAN.module.visible_on_scroll({
        'query': '.subheading + .box.box-default[data-subheading="latest_jobs"] .box-link',
        'threshold' : '40%',
        'autoDetach': true
    }, function (ele, index, list){
        var total_elements = list.length;
        var j_info = ele.querySelector('.box-list-item-heading');
        var j_name = j_info.textContent.trim();
        var j_id = parseInt(j_info.dataset.id);
        var c_info = ele.querySelector('.metas .meta .fa-building + span');
        var c_name = c_info.textContent.trim();
        var c_id = c_info.dataset.id;
        if (c_id) {
            return ['company', 'impression', {
                'position': {
                    'index': index + 1,
                    'total_elements': total_elements
                },
                'job': {
                    'id': j_id,
                    'name': j_name
                },
                'company': {
                    'id': c_id,
                    'name': c_name
                },
                'source': {
                    'element': 'latest jobs',
                    'page': 'home',
                    'href': window.location.href
                },
                'event': {
                    'type': 'impression'
                }
            }];
        }
    });

    // home - Latest jobs interaction
    TEAN.tracker.event.attach('.subheading + .box.box-default[data-subheading="latest_jobs"] .box-link a', 'click', 'clicks', function(e) {
        var ele = e.currentTarget;
        var card = ele.parentElement;
        var list = card.parentElement;
        var index = TEAN.helper.get_element_index(list.children, card);
        var href = ele.attributes.href.textContent;
        var j_info = ele.querySelector('.box-list-item-heading');
        var j_name = j_info.textContent.trim();
        var j_id = parseInt(j_info.dataset.id);
        var c_info = ele.querySelector('.metas .meta .fa-building + span');
        var c_name = c_info.textContent.trim();
        var c_id = c_info.dataset.id;
        if (c_id) {
            return ['company', 'interaction', {
                'position': {
                    'index': index + 1,
                    'total_elements': list.children.length
                },
                'job': {
                    'id': j_id,
                    'name': j_name
                },
                'company': {
                    'id': c_id,
                    'name': c_name
                },
                'source': {
                    'element': 'latest jobs',
                    'page': 'home',
                    'href': window.location.href
                },
                'event': {
                    'type': 'click',
                    'href': href
                }
            }];
        }
    });

    // home - job opportunity of the day
    // impression
    // TEAN.module.visible_on_scroll({
    //     'query': '#mainJobs .jotd',
    //     'threshold': '40%',
    //     'autoDetach': true
    // }, function (ele, index, list) {
    //     console.log(ele, index, list);
    //     if (c_id) {
    //         return ['company', 'impression', {
    //             'company': {
    //                 'id': c_id,
    //                 'name': c_name
    //             },
    //             'job': {
    //                 'id': j_id,
    //                 'name': j_name
    //             },
    //             'source': {
    //                 'element': 'profile_job',
    //                 'page': 'company',
    //                 'href': window.location.href
    //             },
    //             'event': {
    //                 'type': 'impression'
    //             },
    //             'position': {
    //                 'total_jobs': total_jobs,
    //                 'page': page
    //             }
    //         }];
    //     }
    // });

    // interaction
})();
